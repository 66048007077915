html {
  height: 100%;
  width: 100%;
}

body, p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

body {
  font-family: proxima-soft, sans-serif;
  height: 100% !important;
  width: 100%;
}

input,
textarea,
select,
button {
  font-family: proxima-soft, sans-serif;
}

#root {
  height: 100%;
  width: 100%;
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ol, ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}

